import { base } from '@theme-ui/presets';
import merge from 'lodash.merge';

export const baseFontSizes: Record<string, number> = {
  tiny: 12,
  small: 14,
  base: 16,
  large: 22,
  huge: 32,
  mega: 48,
  gigantic: 56,
};

export const baseColors = {
  black: '#000',
  white: '#fff',
  blue: {
    base: '#00AAEE',
    '10': '#cce6ff',
    '20': '#97d0ff',
    '30': '#46bcff',
  },

  baseBlue: '#00AAEE',
  blue10: '#cce6ff',
  blue20: '#97d0ff',
  blue30: '#46bcff',
  blue40: '#00aaee',
  blue50: '#008cc5',
  blue60: '#0075a5',
  blue70: '#005e86',
  blue80: '#004868',

  baseGray: '#262626',
  gray0: '#fcfcfc',
  gray10: '#e3e3e3',
  gray20: '#cacaca',
  gray30: '#b2b2b2',
  gray40: '#9a9a9a',
  gray50: '#838383',
  gray60: '#6d6d6d',
  gray70: '#585858',
  gray80: '#434343',
  gray90: '#303030',
  gray100: '#262626',
};

const baseTheme = {
  ...base,
  /** TODO: Simplify color scheme */
  colors: {
    primary: {
      light: '#449dfe',
      base: '#0984e3',
      dark: '#066fc1',
    },

    secondary: {
      light: '',
      base: '#D6B6D1',
      dark: '',
    },

    success: {
      light: '',
      base: '',
      dark: '',
    },

    errors: {
      light: '',
      base: '',
      dark: '',
    },

    text: {
      light: baseColors.gray40,
      base: '#262626',
    },

    background: {
      light: baseColors.white,
      base: '#f5f3ef',
      dark: baseColors.gray30,
    },

    border: {
      light: '',
      base: baseColors.gray20,
      dark: '',
    },

    social: {
      facebook: '#458eff',
      google: '#DB4437',
    },
    highlight: '#DDDDDD',
    // TODO: Add dark mode
    modes: { dark: {} },
  },
  sizes: {
    none: 0,
    micro: 2,
    tiny: 4,
    small: 8,
    base: 16,
    large: 32,
    huge: 48,
    mega: 64,
    gigantic: 128,
  },
  space: {
    none: 0,
    micro: 2,
    tiny: 4,
    small: 8,
    base: 16,
    large: 32,
    huge: 48,
    mega: 64,
    gigantic: 128,
  },
  fontSizes: {
    atomic: 10,
    micro: 12,
    tiny: 14,
    small: 16,
    base: 18,
    large: 20,
    huge: 25,
    mega: 29,
    gigantic: 33,
  },
  fontWeights: { light: 300, regular: 400, medium: 500, semiBold: 600, bold: 700 },
  radii: {
    none: 0,
    small: 4,
    base: 8,
    large: 16,
  },
};

export default merge({}, baseTheme, {
  colors: {
    text: '#161f18',
  },
  fonts: {
    body:
      'aktiv-grotesk-ext, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'aktiv-grotesk, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 'normal',
    heading: 'bold',
    monospace: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  text: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
  },
  links: {
    header: {
      color: 'primary.base',
      ':hover': {
        color: 'primary.dark',
      },
    },
  },
  sizes: {
    container: 1440,
  },
  layout: {
    container: {
      px: ['base', 'huge'],
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      maxHeight: 80,
      height: 80,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'lightGray',
    },
  },
  buttons: {
    primary: {
      cursor: 'pointer',
      bg: 'primary.base',
      py: 12,
      px: 16,
      fontWeight: 'medium',
      borderRadius: 'base',
      transition: 'all 300ms',
      ':hover': {
        bg: 'primary.light',
      },
    },
    secondary: {},
  },
  images: {
    background: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      objectFit: 'cover',
      zIndex: -1,
    },
  },
  cards: {
    primary: {
      padding: 'small',
      borderRadius: 8,
      boxShadow: '0 8px 16px -8px rgba(0,0,0,0.2)',
    },
    compact: {
      padding: 'tiny',
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      variant: 'text.heading',
      fontSize: 'gigantic',
    },
    h2: {
      variant: 'text.heading',
      fontSize: 'mega',
    },
    h3: {
      variant: 'text.heading',
      fontSize: 'huge',
    },
    h4: {
      variant: 'text.heading',
      fontSize: 'base',
    },
    h5: {
      variant: 'text.heading',
      fontSize: 'small',
    },
    h6: {
      variant: 'text.heading',
      fontSize: 'tiny',
    },
    hr: {},
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    a: {
      color: 'primary.base',
      ':hover': {
        color: 'primary.dark',
      },
    },
    button: {
      cursor: 'pointer',
    },
  },
});
